body {
  overflow-y: scroll;

  &.footer-no-margin {
    footer {
      margin-top: 0;
    }
  }
}

.form-pdf-view {
   header,
   footer,
   .signature-clear-button {
     display: none;
   }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.wizard-loaded {
  position: relative;
  width: 100%;

  .wizard-appear {
    position: relative;
  }

  .wizard-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .wizard-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease;
  }

  .wizard-exit {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(0%);
  }

  .wizard-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 300ms ease;
  }

  &.wizard-reverse {
    .wizard-enter {
      transform: translateX(-100%);
    }

    .wizard-enter-active {
      transform: translateX(0%);
    }

    .wizard-exit-active {
      transform: translateX(100%);
    }
  }
}

blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}
